import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  Wrapper,
  Title,
  GridCard,
  GridIcon,
  GridTitle,
  GridLinks,
  GridLink,
  GridButton,
} from './styles';

const FeaturedResources = () => {
  const { category, post } = useStaticQuery(
    graphql`
      query MyQuery {
        category: allStrapiCategory(
          filter: {featured: {eq: true}}
          limit: 4
          sort: {order: ASC, fields: created_at}
        ) {
          edges {
            node {
              title
              slug
            }
          }
        }
        post: allStrapiKnowledge {
          edges {
            node {
              title
              id
              slug
              class {
                title
              }
            }
          }
        }
      }    
    `
  );

  return (
    <>
      <Title>RECURSOS EM <span>DESTAQUE</span></Title>
      <Wrapper>

        {category.edges.map(({ node }, index) => (
          <GridCard key={index}>
            <GridIcon
              alt="Ícone de um balão de diálogo com uma lâmpada dentro acima dos links de recursos em destaque."
            />
            <GridTitle>{node.title}</GridTitle>
            <GridLinks>
              {post.edges.filter((e) => { return (e.node.class.title === node.title) }).slice(-4).map(e => (
                <GridLink
                  key={e.node.id}
                  cover
                  bg="#fff"
                  to={`/${node.slug}/${e.node.slug}`}
                >
                  {e.node.title}
                </GridLink>
              ))}
            </GridLinks>
            <GridButton cover bg="#fff" to={`/${node.slug}`}>Ver tudo</GridButton>
          </GridCard>
        ))}

      </Wrapper>
    </>
  );
}

export default FeaturedResources;