import React from "react";
import SEO from "../components/seo"

import Header from '../components/Header';
import KnowledgeCenter from '../components/KnowledgeCenter';
import Resources from '../components/Resources';
import FeaturedResources from '../components/FeaturedResources';
import Glossary from '../components/Glossary';
import ForDevelopers from '../components/ForDevelopers';
import Footer from '../components/Footer';
import GoTop from '../components/GoTop';
import GoTopMobile from '../components/GoTopMobile';

import GlobalStyles from '../styles/GlobalStyles';

const Home = () => {
  return (
    <>
      <SEO
        title="SmartEnvios"
      />
      <Header />
      <KnowledgeCenter />
      <Resources />
      <FeaturedResources />
      {/* <Glossary />
      <ForDevelopers /> */}
      <Footer />
      <GoTop />
      <GoTopMobile />
      <GlobalStyles />
    </>
  );
};

export default Home;
