import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import {
  Wrapper,
  GridCard,
  GridTag,
  GridTitle,
  GridDescription,
  GridButton
} from './styles';

const Resources = () => {
  const { posts } = useStaticQuery(
    graphql`
      query KnowledgeResource {
        posts: allStrapiKnowledge(
          limit: 4
          sort: {fields: created_at, order: DESC}
        ) {
          edges {
            node {
              category
              content
              description
              id
              slug
              tag
              title
              class {
                title
                slug
              }
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper>

      {posts.edges.map(post => (
        <GridCard key={post.node.id}>
          <GridTag>{post.node.tag.toUpperCase()}</GridTag>
          <GridTitle>{post.node.category}</GridTitle>
          <GridDescription>{post.node.description}</GridDescription>
          <GridButton cover bg="#fff" to={`/${post.node.class.slug}/${post.node.slug}`}>Começar</GridButton>
        </GridCard>
      ))}

    </Wrapper>
  );
}

export default Resources;