import styled from 'styled-components';
import SendMessage from '../../assets/images/dicas.svg';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const Wrapper = styled.section`
  background: #DCEDF9;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 40px 250px;

  @media (max-width: 1200px) {
    padding: 40px 100px;
  }

  @media (max-width: 800px) {
    padding: 40px 20px;
  }

  @media (max-width: 660px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 20px;
  }
`;

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  font-size: 40px;
  font-family: 'Sora', sans-serif;
  color: #305C82;
  font-weight: bold;

  > span {
    color: #EE7600;
  }

  @media (max-width: 340px) {
    font-size: 30px;
  }

  @media (max-width: 270px) {
    font-size: 25px;
  }

`;

export const GridCard = styled.div`
  text-align: center;
  border-radius: 4px;
  padding: 0px 40px 40px 40px;
  background: #fff;
  border: 1px solid rgba(168,185,213,.6);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  position: relative;

  @media (max-width: 300px) {
    padding: 0 20px 40px;
  }
`;

export const GridIcon = styled(SendMessage)`
  width: 50px;
  height: 40px;
  padding: 3px;
  margin: 20px 0;
  fill: #2A5072;
`;

export const GridTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #3F79AB;
`;

export const GridLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  line-height: 18px;
`;

export const GridLink = styled(AniLink)`
  cursor: pointer;
  color: #294661;
  text-decoration: none;
  margin-bottom: 10px;
  transition: ease-in 0.2s;
  font-size: 14px;

  &:hover {
    color: #489be8;
  }
`;

export const GridButton = styled(AniLink)`
  padding: 16px;
  background: #3F79AB;
  border-radius: 4px;
  font-family: 'Sora', sans-serif;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 100px;
  left: 100px;
  transition: ease-in 0.2s;

  @media (max-width: 900px) {
    right: 50px;
    left: 50px;
  }

  &:hover {
    background: #489be8;
  }
`;