import styled from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  width: auto;
  height: auto;
  padding: 0 150px 60px;

  @media (max-width: 1200px) {
    padding: 0 100px 60px;
  }

  @media (max-width: 800px) {
    padding: 0 20px 30px;
  }

  @media (max-width: 660px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 30px;
  }
`;

export const GridCard = styled.div`
  border-radius: 4px;
  padding: 26px 30px 30px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  position: relative;
  width: 100%;
`;

export const GridTag = styled.span`
  color: #EE7600;
  font-size: 14px;
  font-family: 'Sora', sans-serif;
`;

export const GridTitle = styled.h2`
  font-size: 22px;
  font-weight: bold;
  font-family: 'Sora', sans-serif;
  color: #2A5072;
  margin-top: 20px;
`;

export const GridDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 40px;
  color: #294661;
  font-family: 'Sora', sans-serif;
  font-size: 14px;
`;

export const GridButton = styled(AniLink)`
  background: #EE7600;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  padding: 14px;
  margin-top: 20px;
  font-family: 'Sora', sans-serif;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  left: 30px;
  bottom: 10px;
  transition: ease-in 0.2s;

  &:hover {
    background: #2A5072;
  }
`;