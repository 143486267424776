import styled from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const Wrapper = styled.section`
  background-color: #DCEDF9;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 30px;
  height: auto;

  @media (max-width: 630px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Developer = styled.div`
  padding: 0px 10px;

  @media (max-width: 550px) {
    padding: 0;
  }
`;

export const DeveloperTitle = styled.h3`
  color: #044D75;
  font-size: 40px;
  font-family: 'Sora', sans-serif;
  font-weight: bold;
  margin-bottom: 30px;

  @media (max-width: 360px) {
    font-size: 36px;
  }

  @media (max-width: 340px) {
    font-size: 32px;
  }

  @media (max-width: 300px) {
    font-size: 30px;
  }

  @media (max-width: 280px) {
    font-size: 28px;
  }
`;

export const DeveloperDescription = styled.p`
  font-size: 20px;
  font-family: 'Sora', sans-serif;
  margin-bottom: 40px;
  color: #044D75;
`;

export const DeveloperButton = styled(AniLink)`
  text-decoration: none;
  font-family: 'Sora', sans-serif;
  font-weight: bold;
  width: 150px;
  height: 40px;
  color: #FAF9FD;
  text-align: center;
  padding: 13px;
  border-radius: 4px;
  background: #044D75;
  cursor: pointer;

  &:hover {
    background: #489be8;
  }
`;

export const DeveloperCard = styled.div`
  background: #FAF9FD;
  padding: 25px 30px;
  text-align: left;
  max-height: 320px;
  height: auto;
  width: 430px;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(168,185,213,.6);

  @media (max-width: 630px) {
    margin-top: 30px;
    width: 100%;
    padding: 25px 20px;
    height: auto;
  }
`;

export const CardTitle = styled.h3`
  font-size: 20px;
  font-family: 'Sora', sans-serif;
  font-weight: bold;
  color: #3A6E9C;
  margin-bottom: 20px;
`;

export const CardLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  line-height: 50px;

  @media (max-width: 810px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 386px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardLink = styled(AniLink)`
  font-size: 16px;
  font-family: 'Quicksando', sans-serif;
  color: #294661;
  padding-left: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: #489be8;
  }
`;
